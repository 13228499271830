import React from 'react';

import AppRouter from './routers/AppRouter.jsx';
import 'normalize.css/normalize.css';
import './styles/styles.scss';

function App() {
  return (
    <>
      <AppRouter />
    </>
  )
}

export default App
